// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `horizontal-layout-1 {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}
horizontal-layout-1 #main {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1;
  min-width: 0;
}
horizontal-layout-1 #main > .container {
  position: relative;
  display: flex;
  flex: 1 1 0%;
  width: 100%;
  min-height: 0;
  min-width: 0;
}
horizontal-layout-1 #main > .container > .container {
  position: relative;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  min-width: 0;
}
horizontal-layout-1 #main > .container > .container > .container {
  position: relative;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
horizontal-layout-1 #main > .container > .container > .container content.inner-scroll {
  flex: 1 1 0%;
  min-height: 0;
}
horizontal-layout-1 #main > .container > .container > .container content.inner-scroll > *:not(router-outlet) {
  flex: 1 1 0%;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/horizontal/layout-1/layout-1.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;AADF;AAGE;EACE,kBAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,YAAA;AADJ;AAII;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;AAFN;AAKM;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,YAAA;AAHR;AAMQ;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iCAAA;AAJV;AAQY;EACE,YAAA;EACA,aAAA;AANd;AAQc;EACE,YAAA;AANhB","sourcesContent":["@import \"src/@fuse/scss/fuse\";\n\nhorizontal-layout-1 {\n  display: flex;\n  flex: 1 1 auto;\n  width: 100%;\n  height: 100%;\n\n  #main {\n    position: relative;\n    display: flex;\n    flex: 1 1 auto;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n    min-width: 0;\n\n    // Container 1\n    > .container {\n      position: relative;\n      display: flex;\n      flex: 1 1 0%;\n      width: 100%;\n      min-height: 0;\n      min-width: 0;\n\n      // Container 2\n      > .container {\n        position: relative;\n        display: flex;\n        flex: 1 1 0%;\n        flex-direction: column;\n        min-width: 0;\n\n        // Container 3 (Scrollable)\n        > .container {\n          position: relative;\n          display: flex;\n          flex: 1 1 0%;\n          flex-direction: column;\n          overflow-x: hidden;\n          overflow-y: auto;\n          -webkit-overflow-scrolling: touch;\n\n          // Content component\n          content {\n            &.inner-scroll {\n              flex: 1 1 0%;\n              min-height: 0;\n\n              > *:not(router-outlet) {\n                flex: 1 1 0%;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
