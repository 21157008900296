// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folded:not(.unfolded) :host .nav-link > span {
  opacity: 0;
  transition: opacity 200ms ease;
}
.folded:not(.unfolded) :host.open .children {
  display: none !important;
}
:host .nav-link .collapsable-arrow {
  transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s;
  transform: rotate(0);
}
:host > .children {
  overflow: hidden;
}
:host.open > .nav-link .collapsable-arrow {
  transform: rotate(90deg);
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/navigation/vertical/collapsable/collapsable.component.scss"],"names":[],"mappings":"AAGM;EACE,UAAA;EACA,8BAAA;AAFR;AAOM;EACE,wBAAA;AALR;AAWI;EACE,sEAAA;EACA,oBAAA;AATN;AAaE;EACE,gBAAA;AAXJ;AAgBM;EACE,wBAAA;AAdR","sourcesContent":[":host {\n  .folded:not(.unfolded) & {\n    .nav-link {\n      > span {\n        opacity: 0;\n        transition: opacity 200ms ease;\n      }\n    }\n\n    &.open {\n      .children {\n        display: none !important;\n      }\n    }\n  }\n\n  .nav-link {\n    .collapsable-arrow {\n      transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s;\n      transform: rotate(0);\n    }\n  }\n\n  > .children {\n    overflow: hidden;\n  }\n\n  &.open {\n    > .nav-link {\n      .collapsable-arrow {\n        transform: rotate(90deg);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
