import { Injectable } from '@angular/core';
import { OdooRpcService, ɵa } from '@cogito/angular-odoo';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiAuthService } from '../services/api.auth.service';
import { userConfig } from '../../configs';
import Bugsnag from '@bugsnag/js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { anyOutsideSaleRole, isAdminRole, isAnyOutsideSales, isUserRole } from 'app/helpers/user';
// import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
@Injectable()
export class AuthService {
  private _currentAuthSubject: BehaviorSubject<any>;
  public currentAuth: Observable<any>;

  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _odoo: OdooRpcService,
    private _odooCokkies: ɵa,
    private _router: Router,
    private _apiAuth: ApiAuthService,
    private _http: HttpClient,
  ) {
    this._odoo.init({
      odooServer: '/api/odoo/',
      httpAuth: null,
      database: null,
    });

    this._currentAuthSubject = new BehaviorSubject<any>(null);
    this.currentAuth = this._currentAuthSubject.asObservable();
  }
  public get currentAuthValue() {
    return this._currentAuthSubject.value;
  }

  async getCurrentPermission(): Promise<string> {
    try {
      const allRoles = JSON.parse(localStorage.getItem('allRoles'));
      const userRole = localStorage.getItem('userRole');
      const currentInfo: any = await this.getCurrentSessionInfo();
      let loggedUser ;
      if(isAdminRole(allRoles) && userRole && !isAnyOutsideSales(userRole)){
        loggedUser = {
          ...currentInfo,
          display_name: currentInfo.name,
          email: currentInfo.username,
          isAdmin :false,
          isUser : isUserRole(allRoles)
        }; 
      }else{
        loggedUser = {
          ...currentInfo,
          display_name: currentInfo.name,
          email: currentInfo.username,
          isAdmin :isAdminRole(allRoles),
          isUser : isUserRole(allRoles)
        }; 
      }
      Bugsnag.setUser(currentInfo.uid, currentInfo.username, currentInfo.name);
      this._currentAuthSubject.next(loggedUser);
      if(allRoles && allRoles.implicitRolesForUser.length > 0){
        if(loggedUser.isAdmin){
          return 'admin';
        }
        else if(loggedUser.isUser){
          return 'user';
        }
      }

      if (currentInfo && currentInfo.uid) {
        const loggedUserGroups = await this._odoo
          .call('res.groups', 'search_read', [[['users', '=', currentInfo.uid]]], {})
          .toPromise();
        const userPermissionArray = loggedUserGroups.map((group) => group.name);
        // const isAdmin = userPermissionArray.indexOf(environment.ADMIN_ODOO_GROUP) !== -1;
        // const isManager = userPermissionArray.indexOf(environment.MANAGER_ODOO_GROUP) !== -1;
        // const isUser = userPermissionArray.indexOf(environment.USER_ODOO_GROUP) !== -1;
        const hasShowroom = userPermissionArray.indexOf(environment.SHOWROOM_ODOO_GROUP) !== -1;


        // const hasRenewalLeadsDisplay =
        //   userPermissionArray.indexOf(environment.RENEWAL_ODOO_USER_GROUP_NAME) !== -1;
        const hasPhoneSalesDisplay = false;
        /*userPermissionArray.indexOf(environment.PHONE_SALES_ODOO_USER_GROUP_NAME) !== -1;*/
        let hasRemoteCheckPresent = false;
        environment.PRESELECT_RENEWAL_REMOTE_GROUP.map((group) => {
          if (!hasRemoteCheckPresent) {
            hasRemoteCheckPresent = userPermissionArray.indexOf(group) !== -1;
          }
        });

        // // check lendfoundry permission start
        const hasLendfoundry = userPermissionArray.indexOf(environment.HAS_LENDFOUNDRY) !== -1;
        // check lendfoundry permission end
        const userPermissions = localStorage.getItem('userPermissions');
        if (userPermissions) {
          const parsedPermissions = JSON.parse(userPermissions);
          const isAdmin = parsedPermissions.isAdmin;
          const isManager = parsedPermissions.isManager;
          const isUser = parsedPermissions.isUser;
          // // check phone sale user **** start
          let isPhoneSaleUser = false;
          if (isAdmin || isManager) {
            isPhoneSaleUser = false;
          } else if (isUser) {
            isPhoneSaleUser =
              userPermissionArray.indexOf(environment.PHONE_SALES_ODOO_USER_GROUP_NAME) !== -1;
          }
          // // check phone sale user **** end
          if (isAdmin || isManager || isUser) {
            const loggedUser = {
              ...currentInfo,
              display_name: currentInfo.name,
              email: currentInfo.username,
              hasPhoneSalesDisplay,
              hasRemoteCheckPresent,
              hasLendfoundry,
              ...parsedPermissions,
            };
            Bugsnag.setUser(currentInfo.uid, currentInfo.username, currentInfo.name);
            this._currentAuthSubject.next(loggedUser);
            if (loggedUser.isAdmin) {
              return 'admin';
            } else if (loggedUser.isManager) {
              return 'manager';
            } else if (loggedUser.isUser) {
              return 'user';
            }
          } else {
            return null;
          }
        }
      }
      return null;
    } catch (error) {
      Bugsnag.notify(error);
      return null;
    }
  }

  getCurrentSessionInfo(): Promise<any> {
    return this._odoo.getSessionInfo().toPromise();
  }

  isLoggedIn(): Promise<any> {
    return this._odoo.isLoggedIn().toPromise();
  }

  login(username: string, password: string,google = false,token=""): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let loginResult = null;
        if(google){
          loginResult = await this.googleLogin(token)
        if (loginResult && loginResult.session_id) {
          this._odooCokkies.setSessionId(loginResult.session_id);
        }
        }
        else {
          loginResult = await this.odooLogin({username, password});
          this._odooCokkies.setSessionId(loginResult.session_id);
        }
        if (loginResult && loginResult.uid) {
          if(localStorage.getItem('userRole')){
            localStorage.removeItem('userRole');
          }
          if(localStorage.getItem('allRoles')){
            localStorage.removeItem('allRoles');
          }
          if(localStorage.getItem('userPermissions')){
            localStorage.removeItem('userPermissions');
          }
          const loggedUserGroups = await this._odoo
            .call('res.groups', 'search_read', [[['users', '=', loginResult.uid]]], {})
            .toPromise();
          const allUserRoles = await this._apiAuth.getAllPermissions(loginResult.username);
          let count = 0;
          if (allUserRoles.implicitRolesForUser.length > 0) {
            localStorage.setItem('allRoles',JSON.stringify(allUserRoles));
            count = 1;
            const sessionId = this._odooCokkies.getSessionId();
            await this._apiAuth.generateToken(sessionId);
            const loggedUser = {
              ...loginResult,
              display_name: loginResult.name,
              email: loginResult.username,
              isAdmin : isAdminRole(allUserRoles),
              isUser :isUserRole(allUserRoles),
            };
            const user_id = loginResult.uid;
            const userId = user_id.toString();
            Bugsnag.setUser(userId, loginResult.username, loginResult.name);
            const isPermissionSet = localStorage.getItem('isPermissionSet');
            if (isPermissionSet) {
              localStorage.removeItem('isPermissionSet');
            } else if (!isPermissionSet && count < 1) {
              localStorage.setItem('isPermissionSet', 'true');
            }
              this._currentAuthSubject.next(loggedUser);
            return resolve(loggedUser);
          } else {
            return reject({
              message: 'The user has no permission to use this app.',
            });
          }
        }
        return reject({
          message: 'There is no user existed.',
        });
      } catch (error) {
        Bugsnag.notify(error);
        return reject(error);
      }
    });
  }

  async getUserDefaultPermissions() {
    return new Promise(async (resolve, reject) => {
      const currentInfo: any = await this.getCurrentSessionInfo();
      if (currentInfo && currentInfo.uid) {
        const loggedUserGroups = await this._odoo
          .call('res.groups', 'search_read', [[['users', '=', currentInfo.uid]]], {})
          .toPromise();
        const allRoles = JSON.parse(localStorage.getItem('allRoles'))

        const permissionObj: any = {
          isAdmin : isAdminRole(allRoles),
          isUser : isUserRole(allRoles)
        };
        resolve(permissionObj);
      } else {
        return reject({});
      }
    });
  }
  async setUserPermissions(permission) {
    return new Promise(async (resolve, reject) => {
      const userPermissions = localStorage.getItem('userPermissions');
      const allRoles = JSON.parse(localStorage.getItem('allRoles'));
      if (allRoles) {
        const parsedPermissions = JSON.parse(userPermissions);
        let loggedUser = {
          ...parsedPermissions,
        };
        if (permission.name === '3G Renewal User') {
          loggedUser.hasRenewalLeadsDisplay = true;
          loggedUser.isUser = true;
          loggedUser.isAdmin = false;
          loggedUser.isManager = false;
          loggedUser.isPhoneSaleUser = false;
          loggedUser.hasShowroom = false;
          loggedUser.hasOnitHomeLeadsDisplay = false;
          loggedUser.hasOnitHomeExternalUser = false;
          localStorage.setItem('userRole',permission.role)
        } else if (permission.name === 'OnIt Home User') {
          loggedUser.hasRenewalLeadsDisplay = false;
          loggedUser.isUser = true;
          loggedUser.isAdmin = false;
          loggedUser.isManager = false;
          loggedUser.isPhoneSaleUser = false;
          loggedUser.hasShowroom = false;
          loggedUser.hasOnitHomeLeadsDisplay = true;
          loggedUser.hasOnitHomeExternalUser = true;
          localStorage.setItem('userRole',permission.role)
        } else {
          const userPermissions: any = await this.getUserDefaultPermissions();
          loggedUser = { ...userPermissions };
          loggedUser.hasRenewalLeadsDisplay = false;
          loggedUser.hasOnitHomeLeadsDisplay = false;
          loggedUser.hasOnitHomeExternalUser = false;
          if(isAdminRole(allRoles)){
            localStorage.setItem('userRole',permission.role)
          }
          else if(allRoles.implicitRolesForUser.includes('role:phone-sales')){
            localStorage.setItem('userRole','role:phone-sales');
          }else if(anyOutsideSaleRole(allRoles)){
            localStorage.setItem('userRole',permission.role);
          }else{
            localStorage.setItem('userRole','role:showroom-sales');
          }
        }
        localStorage.setItem('userPermissions', JSON.stringify(loggedUser));
        localStorage.setItem('isPermissionSet', 'true');
        resolve(true);
      }
    });
  }
  async logout() {
    this._fuseProgressBarService.show();
    try {
      this._apiAuth.logout();
      await this._odoo.logout().toPromise();
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      this._fuseProgressBarService.hide();
      this._router.navigateByUrl('/auth/login');
    }
  }
  async googleLogin(token) {
    return new Promise(async(resolve,reject)=>{
      const sUrl = '/api/auth/googleLogin'
      this._http
      .post(sUrl,{token}).subscribe(async (response:any)=>{
        resolve(response);
      },reject)
    })
  }

  async odooLogin(data){
    return new Promise((resolve,reject)=>{
      const sUrl = '/api/auth/login';
      this._http.post(sUrl,data).subscribe((res:any)=>{
        resolve(res.result);
      })
    })
  }
}
