// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fuse-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99998;
}
fuse-progress-bar mat-progress-bar .mat-progress-bar-buffer {
  background-color: #c5c6cb !important;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/progress-bar/progress-bar.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,cAAA;AADF;AAII;EACE,oCAAA;AAFN","sourcesContent":["@import \"src/@fuse/scss/fuse\";\n\nfuse-progress-bar {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  z-index: 99998;\n\n  mat-progress-bar {\n    .mat-progress-bar-buffer {\n      background-color: #c5c6cb !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
