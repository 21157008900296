// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fuse-navigation {
  display: flex;
  flex: 1 0 auto;
}
fuse-navigation > .nav {
  margin: 0;
  padding: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/navigation/navigation.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,cAAA;AADF;AAGE;EACE,SAAA;EACA,UAAA;EACA,WAAA;AADJ","sourcesContent":["@import \"src/@fuse/scss/fuse\";\n\nfuse-navigation {\n  display: flex;\n  flex: 1 0 auto;\n\n  > .nav {\n    margin: 0;\n    padding: 0;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
