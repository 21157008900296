// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 0 0 auto;
  z-index: 3;
}
:host .mat-toolbar {
  background: inherit;
  color: inherit;
  box-shadow: 0px -1px 1px -1px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px -1px 3px 0px rgba(0, 0, 0, 0.12);
}
:host.above {
  position: relative;
  z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,UAAA;AACF;AACE;EACE,mBAAA;EACA,cAAA;EACA,2HAAA;AACJ;AAGE;EACE,kBAAA;EACA,WAAA;AADJ","sourcesContent":[":host {\n  display: flex;\n  flex: 0 0 auto;\n  z-index: 3;\n\n  .mat-toolbar {\n    background: inherit;\n    color: inherit;\n    box-shadow: 0px -1px 1px -1px rgba(0, 0, 0, 0.2),\n      0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px -1px 3px 0px rgba(0, 0, 0, 0.12);\n  }\n\n  &.above {\n    position: relative;\n    z-index: 99;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
